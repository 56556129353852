import { useCallback, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkDocumentsFetching, getDocumentsData } from "selectors/documents";
import Constants from "const/Constants";
import DocumentsActions from "actions/DocumentsActions";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const useDocuments = ({ status: statusFromProps } = {}) => {
  const dispatch = useDispatch();

  const fetching = useSelector(checkDocumentsFetching);

  const data = useSelector(getDocumentsData);

  const [envVars] = useEnvVars();

  const firstCallRef = useRef(true);

  const {
    pageSize = Constants.TABLE_PAGE_SIZE,
    status = statusFromProps,
    page = 1,
    text,
    type,
    sortBy,
    toDate,
    paidWith,
    fromDate,
    sortOrder,
    createdBy,
    createdAt
  } = envVars;

  const hasFilters = !![text, fromDate, toDate, type].find(Boolean);

  const fetchData = useCallback(async(clearList = false, backgroundUpdate = false) => {
    const result = await dispatch(DocumentsActions.fetchDocumentsList(
      status,
      { fromDate, toDate, text, paymentType: type, createdBy, createdAt, paidWith },
      sortBy ? { id: sortBy, desc: sortOrder === "desc" } : null,
      (page - 1) * pageSize,
      Number(pageSize),
      clearList,
      backgroundUpdate
    ));

    return result;
  }, [
    dispatch,
    status,
    fromDate,
    toDate,
    text,
    type,
    sortBy,
    paidWith,
    sortOrder,
    pageSize,
    createdBy,
    createdAt,
    page
  ]);

  const refetch = useCallback((backgroundUpdate) => {
    return fetchData(false, backgroundUpdate);
  }, [fetchData]);

  useLayoutEffect(() => {
    fetchData(firstCallRef.current);

    firstCallRef.current = false;

    return Utils.setInterval(
      () => fetchData(false, true), Constants.DATA_LIST_UPDATE_INTERVAL
    );
  }, [fetchData]);

  return {
    fetching,
    hasFilters,
    data,
    refetch
  };
};

export default useDocuments;
